<template>
	<tr class="tr-monteiA-infos">
		<td class="monteIA-horseFiche">
			<router-link :to="{ name: 'horseFiche', params: { horse_id: params.mare.horse_id }}">
				{{ params.mare.horse_nom }}
			</router-link>
		</td>
		<td>{{ params.mare.horse_transpondeur }}</td>
		<td>
			<template v-if="params.prev_sms">{{ params.prev_sms.seasonmarestallion_status }}</template>
			<template v-else-if="params.season_mare.seasonmare_status">{{ $t('gynecologie.'+params.season_mare.seasonmare_status) }}</template>
		</td>
		<td>
			<template v-if="params.contact">{{ params.contact.contact_firstname }} {{ params.contact.contact_lastname }}</template>
			<template v-else-if="params.tiers">{{ params.tiers.tiers_rs }}</template>
		</td>
		<td>
			<template v-if="params.contact"><template v-if="params.contact.phone.length > 0">{{ formatPhone(params.contact.phone[0]) }}</template></template>
			<template v-else-if="params.tiers"><template v-if="params.tiers.phone.length > 0">{{ formatPhone(params.tiers.phone[0]) }}</template></template>
		</td>
		<td>
			<template v-if="params.mare.horse_nom">
				<br class="d-md-none">
				<b-button v-if="!contract" :class="class_contract" size="sm" variant="secondary btn-min-with" @click="createContract">
				{{ $t('monte.contrat_a_faire') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
				</b-button>
				<b-button v-else-if="contract.last_avenant && contract.last_avenant.avenant_status == 3" class="green" size="sm" variant="secondary btn-min-with" @click="openContract">
					{{ $t('monte.contrat_signe') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span :class="class_contract"> €</span>
				</b-button>
				<b-button v-else class="red" size="sm" variant="secondary btn-min-with" @click="openContract">
					{{ $t('monte.contrat_a_signe') }} <font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
					<span :class="class_contract"> €</span>
				</b-button>
			</template>
		</td>
		<td>
			<b-button v-if="params.mare.horse_nom" size="sm" @click="openActe" :class="params.season_mare && params.season_mare.seasonmare_sanitary ? 'green' : 'red'">
				{{ $t('monte.sanitaire') }} <font-awesome-icon :icon="['fal', 'syringe']"></font-awesome-icon>
			</b-button>
		</td>
		<td>
			<b-button-group class="ml-2 ml-md-0">
				<b-dropdown @click="edit" :text="$t('monte.modifier')" split variant="secondary btn-sm ">
					<b-dropdown-item v-if="params.mare.horse_nom" @click="openAddActe">{{ $t('monte.saillie') }}</b-dropdown-item>
					<b-dropdown-item @click="deleteSchedule">{{ $t('monte.supprimer') }}</b-dropdown-item>
				</b-dropdown>
			</b-button-group>
		</td>
		<td>
			{{ commentaire_formatted }}
		</td>
		<td>
			<template v-if="params.mare.lieu_stationnement">
				{{ params.mare.lieu_stationnement.tiers_rs }}
			</template>
		</td>
	</tr>
</template>

<style scoped>
	.btn.red {
		border-color: #F21207;
		color: #F21207;
	}
	.btn.green {
		border-color: #569E18;
		color: #569E18;
	}
</style>

<script type="text/javascript">
	import Vue from 'vue'
	// import Contract from '@/mixins/Contract'
	import Tiers from '@/mixins/Tiers'
	import Gynecologie from '@/mixins/Gynecologie'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'CellCalendarIABeta',
		mixins: [Tiers, Gynecologie, Shutter, MonteShutters],
		props: ['schedule', 'params', 'edit_schedule', 'delete_schedule'],
		data () {
			return {
				contract: null,
				mare_status: null,
				season_mare: null,
				class_contract: '',
				sms: null
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.params.contract) {
					this.class_contract = this.params.solde ? 'd-none' : 'text-danger'
					this.contract = this.params.contract
				}
			},

			async createContract() {
				if(this.params.mare.horse_id) {
					const tiers = await this.loadTiersHorse(this.params.mare.horse_id)
					if(tiers.length == 0) {
						this.failureToast("monte.jument_no_tiers")
						this.showAjoutTiers()
						return false
					}

					const contract_id = await this.addContractOnlineFromHorseBis(this.params.mare.horse_id)
					this.infos_contract = await this.getContractOnlineBis(contract_id)
					this.contract = this.infos_contract[0].contract
					this.contract.last_avenant = this.infos_contract[0].avenant
				} else {
					const contract_id = await this.addContractOnlineBis()
					this.infos_contract = await this.getContractOnlineBis(contract_id)
					this.contract = this.infos_contract[0].contract
					this.contract.last_avenant = this.infos_contract[0].avenant
				}
				this.openContract()
			},

			showAjoutTiers() {
				this.shutterPanel().keepGiven('monte-ia-calendar-beta')
				const shutter = this.monteShutters['ajout-tiers-mare']
				shutter.props.horse = this.params.mare

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-tiers-mare')
					this.createContract()
				}

				this.shutterPanel().open(shutter)
			},

			async openContract() {
				if(this.shutterPanel().isOpen('contrat')) {
	                this.shutterPanel().close('contract-a-valider')
	                this.shutterPanel().close('contract-bons-valider')
	                this.shutterPanel().close('contract-invoices')
					this.shutterPanel().close('contrat')
					return false
				}

				this.shutterPanel().keepGiven('monte-ia-calendar-beta')
				const shutter = this.monteShutters['contrat']
				shutter.props.contract_id = this.contract.contract_id
				shutter.props.stallion_id = this.params.stallion.horse_id

				shutter.onOk = () => {
					this.shutterPanel().close('contrat')
					this.init_component()
				}

				this.shutterPanel().open(shutter)
			},

			edit() {
				this.$emit('update:edit_schedule', {schedule_info: this.params, schedule_id: this.schedule, horse_id: this.params.stallion.horse_id, season_mare: this.season_mare})
			},

			deleteSchedule() {
				this.$emit('update:delete_schedule', {schedulehorse_id: this.params.id})
			},

			openActe() {
				if(this.shutterPanel().isOpen('acte-jument')) {
					this.shutterPanel().close('acte-jument')
					return false
				}

                this.shutterPanel().keepGiven('monte-ia-calendar-beta')
				const shutter = this.monteShutters['acte-jument']
				shutter.props.horse_id = this.params.mare.horse_id
				shutter.props.horse_nom = this.params.mare.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('acte-jument')
				}

				this.shutterPanel().open(shutter)
			},

			openAddActe() {
				this.shutterPanel().keepGiven('monte-ia-calendar-beta')
				const shutter = this.monteShutters['ajout-acte']
				shutter.props.horses_ids = [this.params.mare.horse_id]
				shutter.props.actes_ids = this.params.saillie_id ? [this.params.saillie_id] : []
				shutter.props.group_preselect = "REPRO"
				shutter.props.type_preselect = "REPRO_INSMINATION_ARTIFICIELLE_EN_FRAIS"
				shutter.props.date_preselect = this.params.saillie_id ? null : new Date(this.params.date)
				shutter.props.preselect = this.params.stallion.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-acte')
				}

				this.shutterPanel().open(shutter)
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			}
		},

		computed: {
			commentaire_formatted() {
				return this.params.commentaire// ? this.params.schedulehorse_commentaire.substr(0, 25) + '...' : ''
			}
		}
	}

</script>